// Portfolio.js

import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Portfolio = () => {
  return (
    <div>
         
         <MainHeader />

         <span id="top"></span>
        <div className="mainpage">

            <p className="menuLinks">
              <a href="/">/home</a> 
              <a href="about">/about</a> 
              <a href="services">/services</a> 
              <a href="portfolio" className="active">/portfolio</a> 
              <a href="blog">/blog</a> 
              <a href="faq">/faq</a> 
              <a href="contact">/contact</a> 
            </p>
            
            <h1>Selected Project Portfolio</h1>
            <p>We've done a kajillion NFT projects. Here are a sampling of them!</p>

            <h2>Projects We Worked On That Minted Out.</h2>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-superfuzz-good.png" /><br></br>
                <b>SuperFuzz Good Guys</b>, 7,777 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/superfuzz-the-good-guys">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-superfuzz-bad.png" /><br></br>
                <b>SuperFuzz Bad Batch</b>, 777 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/superfuzz-the-bad-batch">OpenSea</a>)
            </div>
            
            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-pppandas.png" /><br></br>
                <b>PPPandas</b>, 4,000 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/thepppandas">OpenSea</a>)
            </div>
            
            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-prayinghands.png" /><br></br>
                <b>Praying Hands Club</b>, 2,222 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/prayinghandsclub">OpenSea</a>)
            </div>
            
            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="loodog1.png" /><br></br>
                <b>LoopieLooNFT</b>, 9,663 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/loopieloo">OpenSea</a>)
            </div>
            
            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="tigonpromo.jpg" /><br></br>
                <b>Order of the Tigons</b>, ~5,000 NFTs (<a target="_blank" rel="noreferrer" href="https://www.thetadrop.com/marketplace/edition/type_tpqv6d16v5fp0gudtt0vyf6qsqm">ThetaDrop</a>)
            </div>
            
            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="yotw-sample.jpg" /><br></br>
                <b>Year of the Woman</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/yearofthewoman">OpenSea</a>)
            </div>

            <div className="clear"></div>
                    

            <h2>Projects We Worked On That Are Minting Now.</h2>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-chefdogs.gif" /><br></br>
                <b>Chef Dogs: Mutts</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mint.chefboirdoge.com/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/chefboirdogemutts">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="mutts-gen2.gif" /><br></br>
                <b>Chef Dogs: Mixed Breeds</b>, 15,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mintgen2.chefboirdoge.com">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/cbrdmixedbreeds">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-sandhelm.gif" /><br></br>
                <b>Sandhelm</b>, 9,999 NFTs (<a target="_blank" rel="noreferrer" href="https://mintgen2.chefboirdoge.com">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/sandhelm">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-sweetnftreat.png" /><br></br>
                <b>SweetNFTreat</b>, 7,777 NFTs (<a target="_blank" rel="noreferrer" href="https://cupcakes.nftgreetings.xyz/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/sweetnftreat">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-kittycat.jpg" /><br></br>
                <b>I'm a Kitty Cat</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mintkitty.imakittycat.com/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/imakittycat">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="astralpioneers-logo.jpg" /><br></br>
                <b>Astral Pioneers</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mint.astralpioneers.com/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/astralpioneers">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="spiritualbeingssample.jpg" /><br></br>
                <b>Spiritual Beings</b>, 11,111 NFTs (<a target="_blank" rel="noreferrer" href="https://mint.spiritualbeings.io/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/spiritualbeingscollection">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="9a5114b7f92693f498196cca6f8853d8.gif" /><br></br>
                <b>GlitchDeck</b>, 8,100 NFTs (<a target="_blank" rel="noreferrer" href="https://glitchdeck.xyz/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/glitchdeck">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="cheekyrangasample.jpg" /><br></br>
                <b>Cheeky Rangas</b>, 9,700 NFTs (<a target="_blank" rel="noreferrer" href="https://mint.cheekyrangas.com/">Mint now for free</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/cheekyrangas">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="innerkiddos.jpg" /><br></br>
                <b>Inner Kiddos Club</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mint.innerkiddosclub.com/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/innerkiddosclub">OpenSea</a>)
            </div>

            <div className="clear"></div>

            <h2>Projects We Worked On That Are Minting Soon.</h2>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="nft-sample-lapinc.jpg" /><br></br>
                <b>LAPiNC</b>, 8,282 NFTs (<a target="_blank" rel="noreferrer" href="https://www.lapinc.io/">Web Site</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="nft-sample-fawc2.jpg" /><br></br>
                <b>FAWC</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mint.fawc.house/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/fawchouse">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="nft-sample-mightymetas.jpg" /><br></br>
                <b>MightyMetas</b>, 9,999 NFTs (<a target="_blank" rel="noreferrer" href="https://www.mightymetas.com/">web site</a>)
            </div>

            
            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="divasfeatured.gif" /><br></br>
                <b>DIVAS by Blaq</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mint.divasbyblaq.art/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/divas-by-blaq">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="dstfeatured2.gif" /><br></br>
                <b>DST Divas</b>, 3,000 NFTs (<a target="_blank" rel="noreferrer" href="https://dst.divasbyblaq.art/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/dst-divas-by-blaq">OpenSea</a>)
            </div>


            <div className="clear"></div>

            <h2>Jim's Personal NFT Sets.</h2>

            <p>Various art projects from Jim Dee of GenerativeNFTs.</p>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="nftuxedocats.png" /><br></br>
                <b>NFTuxedoCats</b>, 5,174 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/nftuxedocats">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="loodog1.png" /><br></br>
                <b>LoopieLooNFT</b>, 9,663 NFTs (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/loopieloo">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="9a5114b7f92693f498196cca6f8853d8.gif" /><br></br>
                <b>GlitchDeck</b>, 8,100 NFTs (<a target="_blank" rel="noreferrer" href="https://glitchdeck.xyz/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/glitchdeck">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="zitty.png" /><br></br>
                <b>ZittyBrits</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x0d6a3cdca3658ab4f0f0589e445a9b77dabc6df1#writeContract">Mint from contract</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/zittybrits">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="Eo79I-JiZRlpLQsBPBJan3nZbnzNjKk4yGut0vKfjhs.png" /><br></br>
                <b>TEETHscriptions</b>, 10,000 NFTs (<a target="_blank" rel="noreferrer" href="https://facetnft.com/collection/teethscriptions/mint">Mint on FacetNFT</a>, <a target="_blank" rel="noreferrer" href="https://facetnft.com/collection/teethscriptions">Facet</a>)
            </div>
            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="CattleDog-400px.gif" /><br></br>
                <b>Jim's 1/1 Art</b>, 1/1 NFT Collection by Jim (<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/swiggajuice">OpenSea</a>)
            </div>

            
            <h2>NFT Greeting Cards You Can Send to Others.</h2>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-metalove.jpg" /><br></br>
                <b>MightyMetas Love Cards</b>, 1,000 NFTs (<a target="_blank" rel="noreferrer" href="https://mightymetas.nftgreetings.xyz/">Free Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/mighty-metas-love">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="airabbits.gif" /><br></br>
                <b>AI Well-Wishing Rabbits</b>, 500 NFTs (<a target="_blank" rel="noreferrer" href="https://rabbits.nftgreetings.xyz/">Free Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/well-wishing-rabbits">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-hotairballoons.jpg" /><br></br>
                <b>AI Hot Air Balloons</b>, 485 NFTs (<a target="_blank" rel="noreferrer" href="https://hotairballoons.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/ai-hot-air-balloons">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-babysfirstnft.jpg" /><br></br>
                <b>AI Animal Art: Baby's First NFT</b>, 640 NFTs (<a target="_blank" rel="noreferrer" href="https://babysfirstnft.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/babys-first-nft">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-moons.jpg" /><br></br>
                <b>AI Moons: Happy Birthday</b>, 508 NFTs (<a target="_blank" rel="noreferrer" href="https://aimoons.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/ai-birthday-moons">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-formalflorals.jpg" /><br></br>
                <b>AI Formal Florals</b>, 410 NFTs (<a target="_blank" rel="noreferrer" href="https://formalflorals.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/ai-formal-florals">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-basketanimals.jpg" /><br></br>
                <b>AI Basket Animals</b>, 476 NFTs (<a target="_blank" rel="noreferrer" href="https://basketanimals.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/ai-basket-animals">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-cakescrowns.jpg" /><br></br>
                <b>RenAIssance Cakes & Crowns</b>, 502 NFTs (<a target="_blank" rel="noreferrer" href="https://cakescrowns.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/cakes-and-crowns">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-bouquets.jpg" /><br></br>
                <b>RenAIssance Bouquest & Butterflies</b>, 499 NFTs (<a target="_blank" rel="noreferrer" href="https://bouquets.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/bouquets-butterflies">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="client-sweetnftreat.png" /><br></br>
                <b>SweetNFTreat</b>, 7,777 NFTs (<a target="_blank" rel="noreferrer" href="https://cupcakes.nftgreetings.xyz/">Mint now</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/sweetnftreat">OpenSea</a>)
            </div>

            <div className="portfolioItemWrapper">
                <img alt="cool pic" className="portfolioImages" src="feature-badai.jpg" /><br></br>
                <b>Bad AI Animals</b>, 400 NFTs (<a target="_blank" rel="noreferrer" href="https://badaibday.nftgreetings.xyz/">Mint</a>, <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/bad-ai-bday">OpenSea</a>)
            </div>

            <div className="clear"></div>
            
            <h2>🚀 Let's launch a project for you!</h2>
            <p>To get started working with us: ➡️➡️➡️ Drop Jim a line at <b>Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>            

            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>

            
        </div>

        <MainFooter />

    </div>
  );
}

export default Portfolio;
